import { vars } from '../helpers';
import * as convertors from '../convertors';

export const getGroupCssVars = vars(({ styleParams: { booleans } }) => ({
  'breadcrumbs-display': convertors.display(booleans.showBreadcrumbs),
  'member-count-display': convertors.display(booleans.showMemberCount),
  'media-author-display': convertors.display(booleans.showMediaAuthorOverlay),
  'follow-button-display': convertors.display(booleans.showFollowButton),
  'group-activity-display': convertors.display(booleans.showActivity),
  'member-badges-display': convertors.display(booleans.showMembersBadges),
}));
