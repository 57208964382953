import * as about from './about';
import * as header from './header';
import * as media from './media';
import * as layout from './layout';

export const styles = {
  ...about.styles,
  ...header.styles,
  ...media.styles,
  ...layout.styles,
};

export const settings = {
  ...about.settings,
  ...header.settings,
  ...media.settings,
  ...layout.settings,
};

export { about, header, media, layout };
