import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useHref } from '@wix/tpa-router/react';

import { groupActionClick } from '@wix/bi-logger-groups/v2';

import {
  selectFeedPermissionsByGroup,
  selectGroupSlug,
  selectIsGroupSecret,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Divider } from 'wui/Divider';
import { IconButton } from 'wui/IconButton';
import { ShareDialog } from 'wui/ShareDialog';
import {
  Share as ShareIcon,
  Search as SearchIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { GroupActions } from '../GroupActions';
import { GroupSearch } from '../GroupSearch';
import { ApplicationBreadcrumbs } from '../ApplicationBreadcrumbs';

import classes from './styles.scss';

interface IMobileControlsProps {
  groupId: string;
}

export function MobileControls(props: IMobileControlsProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const slug = useSelector(selectGroupSlug(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));
  const { canViewPosts } = useSelector(selectFeedPermissionsByGroup(groupId));

  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  return (
    <Box align="space-between">
      <ApplicationBreadcrumbs data-hook="group-header-breadcrumbs" />

      <Box gap="SP3">
        <Show if={canViewPosts}>
          <IconButton
            data-hook="search-button"
            icon={<SearchIcon />}
            className={classes.iconButton}
            onClick={handleOpenSearchDrawer}
          />
        </Show>

        <Show if={canViewPosts}>
          <Divider orientation="vertical" />
        </Show>

        <Hide if={isSecretGroup}>
          <IconButton
            aria-label={t('groups-web.a11y.share-group')}
            data-hook="share-button"
            icon={<ShareIcon />}
            className={classes.iconButton}
            onClick={handleOpenShareModal}
          />
        </Hide>
        <GroupActions groupId={groupId} data-hook="group-actions" />
      </Box>

      <ShareDialog
        isOpen={isShareOpen}
        data={{ url: groupUrl }}
        onClose={handleCloseShareDialog}
        title={t('groups-web.group.actions.share.group')}
      />

      <GroupSearch
        groupId={groupId}
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </Box>
  );

  function handleOpenSearchDrawer() {
    setIsSearchOpen(true);
  }

  function handleCloseShareDialog() {
    setIsShareOpen(false);
  }

  function handleOpenShareModal() {
    setIsShareOpen(true);

    bi.report(
      groupActionClick({
        origin: 'arrow',
        action: 'share',
        group_id: groupId,
      }),
    );
  }
}

MobileControls.displayName = 'MobileControls';
