import React from 'react';

import { Container } from 'wui/Container';
import { Paper } from 'wui/Paper';

import { ApplicationBreadcrumbs } from '../ApplicationBreadcrumbs';
import { CoverImage } from '../CoverImage';
import { GroupDetails } from '../GroupDetails';
import { Navigation } from '../Navigation';

import classes from './styles.scss';

interface ILargeImageLayoutProps {
  groupId: string;
}

export function LargeImageLayout(props: ILargeImageLayoutProps) {
  const { groupId } = props;

  return (
    <Paper
      shadow={false}
      sideBorders={false}
      topBottomBorders={false}
      className={classes.root}
    >
      <Container margin="SP3 auto">
        <ApplicationBreadcrumbs data-hook="group-header-breadcrumbs" />
      </Container>
      <Container fluid margin="SP3 auto">
        <CoverImage groupId={groupId} />
      </Container>
      <Container gap="SP5" margin="SP5 auto 0">
        <GroupDetails groupId={groupId} data-hook="group-header-details" />
        <Navigation data-hook="group-header-navigation" />
      </Container>
    </Paper>
  );
}

LargeImageLayout.displayName = 'LargeImageLayout';
