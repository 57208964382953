import React from 'react';
import {
  Breadcrumbs as TPABreadcrumbs,
  BreadcrumbsProps,
} from 'wix-ui-tpa/cssVars';

export type { BreadcrumbsItem } from 'wix-ui-tpa/cssVars';

export interface IBreadcrumbsProps extends BreadcrumbsProps {
  links?: boolean;
}

export function Breadcrumbs(props: IBreadcrumbsProps) {
  const { onClick = () => {}, links = true, items, ...rest } = props;

  return (
    <TPABreadcrumbs
      onClick={onClick}
      items={items.map((item) => ({
        ...item,
        href: links ? item.href : (undefined as unknown as string),
      }))}
      {...rest}
    />
  );
}

Breadcrumbs.displayName = 'wui/Breadcrumbs';
