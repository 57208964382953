import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import type { ImageFocalPoint } from 'wui/Media';

import RepositionIcon from 'assets/icons/Reposition.svg';
import { clamp, lerp } from 'common/utils/utils';

import classes from './FocalPointSelect.scss';

interface IFocalPointSelectProps {
  value: ImageFocalPoint;

  onActiveChange(active: boolean): void;
  onChange(value: ImageFocalPoint): void;
}

export function FocalPointSelect(props: IFocalPointSelectProps) {
  const { t } = useTranslation();

  const [active, setIsActive] = React.useState(false);

  return (
    <Box
      width="100%"
      height="100%"
      align="center"
      verticalAlign="middle"
      className={classes.root}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        variant="basic"
        prefixIcon={<RepositionIcon />}
        className={classes.button}
      >
        {t('groups-web.reposition.drag-cta')}
      </Button>
    </Box>
  );

  function handleMouseMove(event: React.MouseEvent) {
    if (!active) {
      return;
    }

    handleChange(event.movementX * -1, event.movementY * -1);
  }

  function handleTouchMove(event: React.TouchEvent) {
    const touch = event.touches.item(0);

    if (!active) {
      return;
    }

    handleChange(touch.pageX, touch.pageY);
  }

  function handleChange(x: number, y: number) {
    props.onChange({
      x: clamp(lerp(props.value.x, props.value.x + x, 0.3), 0, 100),
      y: clamp(lerp(props.value.y, props.value.y + y, 0.3), 0, 100),
    });
  }

  function handleTouchEnd() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseUp() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseLeave() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseDown(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(true);
    props.onActiveChange(true);
  }

  function handleTouchStart(event: React.TouchEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(true);
    props.onActiveChange(true);
  }
}

FocalPointSelect.displayName = 'FocalPointSelect';
