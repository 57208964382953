import { useMemo } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

type IFormattedParts = {
  [key in Intl.DateTimeFormatPartTypes]: any;
};

export function useDatePartsFormatter(options?: Intl.DateTimeFormatOptions) {
  const { language } = useEnvironment();

  const formatter = useMemo(() => {
    // Currently Wix identifies supported languages by “macro-languages” only.
    // For example, ‘zh’ refers to Chinese, which included many subtypes/dialects based on regions(TW, HK, ...)
    // or scripts(Simplified Han, Traditional Han) which may defer in character types.
    // The default fallback for “zh” is Simplified Chinese (zh-CN, zh-Hans,…) characters
    // which is not the supported script type of Wix:
    // currently being Traditional Chinese (zh-Hant, zh-TW, ...).
    //
    // In this specific case, an additional language tag need to be specified to allow 3rd party to obtain the correct complete locale (Traditional Chinese).
    // zh” => “zh-Hans” (Simplified Chinese) | “zh” => “zh-Hant” (Traditional Chinese)
    const locale = language === 'zh' ? 'zh-Hant' : language;

    return new Intl.DateTimeFormat(locale, options);
  }, [language]);

  return function (date: Date | number | undefined): IFormattedParts {
    if (typeof date === 'undefined') {
      return {} as IFormattedParts;
    }

    return formatter.formatToParts(new Date(date)).reduce(
      (acc, part) => ({
        ...acc,
        [part.type]: part.value,
      }),
      {} as IFormattedParts,
    );
  };
}
