import { DiscussionPage } from '../DiscussionPage/loadable';
import { MediaPage } from '../MediaPage/loadable';
import { AboutPage } from '../AboutPage/loadable';
import { EventsPage } from '../EventsPage/loadable';
import { MembersPage } from '../MembersPage/loadable';
import { FilesPages } from '../FilesPage/loadable';
import { CustomTabPage } from '../CustomTabPage/loadable';

export const ROUTES = [
  {
    state: 'group.discussion',
    dataHook: 'discussion-page',
    component: DiscussionPage,
  },
  {
    state: 'group.media',
    dataHook: 'media-page',
    component: MediaPage,
  },
  {
    state: 'group.files',
    dataHook: 'files-page',
    component: FilesPages,
  },
  {
    state: 'group.about',
    dataHook: 'about-page',
    component: AboutPage,
  },
  {
    state: 'group.events',
    dataHook: 'events-page',
    component: EventsPage,
  },
  {
    state: 'group.members',
    dataHook: 'members-page',
    component: MembersPage,
  },
  {
    state: 'group.custom.tab',
    dataHook: 'custom-tab-page',
    component: CustomTabPage,
  },
];
