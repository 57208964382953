import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { selectGroupBySlug } from 'store/selectors';
import {
  Breadcrumbs,
  type BreadcrumbsItem,
  type IBreadcrumbsProps,
} from 'wui/Breadcrumbs';

import classes from './ApplicationBreadcrumbs.scss';

export function ApplicationBreadcrumbs(props: Partial<IBreadcrumbsProps>) {
  const { className, ...rest } = props;

  const router = useRouter();
  const { t } = useTranslation();
  const { isViewer } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const group = useSelector(selectGroupBySlug(params.slug));

  return (
    <Breadcrumbs
      links={isViewer}
      aria-label="breadcrumbs"
      className={cls(classes.root, className)}
      onClick={isViewer ? undefined : handleClick}
      items={[
        {
          id: 'home',
          value: t(['groups-web.breadcrumbs.home', 'Home']),
          href: router.href('home', {}, { absolute: true }),
        },
        {
          id: 'groups',
          value: t('groups-web.breadcrumbs.group-list'),
          href: router.href('groups', {}, { absolute: true }),
        },
        {
          id: 'group',
          value: group.name || t('groups-web.breadcrumbs.group-title-default'),
          href: router.href('group.discussion.feed', {}, { absolute: true }),
        },
      ]}
      {...rest}
    />
  );

  function handleClick(item: BreadcrumbsItem) {
    switch (item.id) {
      case 'home':
        router.go('home');
        break;

      case 'groups':
        router.go('groups.list');
        break;

      case 'group':
        router.go('group.discussion.feed');
        break;
    }
  }
}

ApplicationBreadcrumbs.displayName = 'ApplicationBreadcrumbs';
