import React from 'react';

import { useSelector } from 'react-redux';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { selectGroupIdBySlug } from 'store/selectors';
import { Container, type IContainerProps } from 'wui/Container';

import { ECoverImageLayout } from 'settings/constants';
import { styles as stylesParams } from 'settings/group';

import {
  LargeImageLayout,
  MobileLayout,
  ThumbnailImageLayout,
} from './layouts';

export function Header(props: IContainerProps) {
  const { params } = useCurrentStateAndParams();
  const environment = useEnvironment();
  const styles = useStyles();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const layout = styles.get(stylesParams.coverImageLayout);
  const hasImage = layout !== ECoverImageLayout.Blank;

  if (environment.isMobile) {
    return (
      <Container fluid {...props}>
        <MobileLayout withImage={hasImage} groupId={groupId} />
      </Container>
    );
  }

  switch (layout) {
    case ECoverImageLayout.Large:
      return (
        <Container fluid data-layout-type="large" {...props}>
          <LargeImageLayout groupId={groupId} />
        </Container>
      );

    case ECoverImageLayout.Thumbnail:
    case ECoverImageLayout.Blank:
    default:
      return (
        <Container data-layout-type={layout} {...props}>
          <ThumbnailImageLayout withImage={hasImage} groupId={groupId} />
        </Container>
      );
  }
}

Header.displayName = 'Header';
