import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';
import { COMPONENT } from 'settings/constants';
import type { IControllerProps } from 'controller/types';

import { GroupPage } from './GroupPage';

COMPONENT.name = 'GroupPage';

function GroupWidget(props: WidgetProps<IControllerProps>) {
  return (
    <SocialGroupsApp {...props}>
      <GroupPage />
    </SocialGroupsApp>
  );
}

GroupWidget.displayName = 'GroupWidget';

export default GroupWidget;
