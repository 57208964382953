import React, { useMemo } from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { type TabItem, Tabs, type ITabsProps } from 'wui/Tabs';

import { GroupAppKey } from 'store/groups/types';
import {
  selectGroupBySlug,
  selectGroupIdBySlug,
  selectInstalledApplications,
} from 'store/selectors';

import { useApplicationsNavigation } from './hooks';

import classes from './Navigation.scss';

export function Navigation(props: Partial<ITabsProps<GroupAppKey>>) {
  const { className, ...rest } = props;

  const bi = useBi();
  const router = useRouter();
  const { state, params } = useCurrentStateAndParams();
  const { isRTL, isMobile } = useEnvironment();

  const group = useSelector(selectGroupBySlug(params.slug));
  const navigation = useApplicationsNavigation(group);
  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const applications = useSelector(selectInstalledApplications(groupId));

  const items = useMemo<TabItem[]>(
    () =>
      applications.map((application) => {
        const target = navigation[application.key as GroupAppKey];

        return {
          ...target,
          title: application.customName || target.title,
        };
      }),
    [applications, navigation],
  );

  const active = useMemo(() => {
    const application = state.data?.application as GroupAppKey;

    switch (application) {
      case GroupAppKey.CUSTOM_APP:
        return navigation[params.tabId.toUpperCase() as GroupAppKey];

      default:
        return navigation[application];
    }
  }, [navigation, params.tabId, state.data?.application]);

  return (
    <Tabs
      skin="clear"
      items={items}
      aria-labelledby="group-title"
      alignment={isRTL ? 'right' : 'left'}
      variant={isMobile ? 'fullWidth' : 'fit'}
      onChange={handleTabClick}
      value={active?.id}
      className={cls(classes.root, className, { [classes.mobile]: isMobile })}
      isTablist={false}
      {...rest}
    />
  );

  function handleTabClick(tabId: GroupAppKey) {
    const { [tabId]: target } = navigation;

    router.go(target.state, { ...target.params, disableScrollToTop: true });

    bi.report(
      groupsTabClicked({
        name: target.id,
        origin: 'tabs',
        group_id: groupId,
      }),
    );
  }
}

Navigation.displayName = 'Navigation';
