import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import * as common from 'settings/common';
import * as wui from 'settings/wui';
import * as group from 'settings/group';
import { getGroupCssVars } from 'settings/group/vars';
import * as feed from 'settings/feed';
import { getFeedCssVars } from 'settings/feed/vars';

export default {
  ...common.styles,
  ...wui.styles,
  ...feed.styles,
  ...group.styles,
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => ({
  ...getGroupCssVars(params),
  ...getFeedCssVars(params),
});
